<template>
  <div :style="`font-family:${font},sans-serif`">
    <!-- <div>
      <p>{{ this.rowData }}</p>
    </div> -->
    <!-- {{ this.rows }} -->
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <b-button class="shadow" variant="danger" @click="$router.push({ name: 'borrowkuruplun' })"> กลับ </b-button>
          <span v-if="status_user == 'ผู้ใช้ทั่วไป'"></span>
          <span v-else>
            <span v-if="this.rowData.status_type === 'ยืม'">
              <b-button variant="success ml-1" @click="loan_approval('อนุมัติยืม')"> อนุมัติยืม </b-button>
              <b-button variant="danger ml-1" @click="loan_approval('ไม่อนุมัติยืม')"> ไม่อนุมัติยืม </b-button>
            </span>

            <span v-else-if="this.rowData.status_type === 'อนุมัติยืม'"
              ><b-button variant="info ml-1" @click="return_borrow"> ส่งคืน </b-button></span
            >
          </span>

          <b-button variant="outline-primary ml-1" @click="exportpdf"> พิมพ์ใบยืมทรัพย์สิน </b-button>
          <div class="title head">
            <p class="text-center h1">รายการยืมทรัพย์สิน</p>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">เลขทะเบียน :</div>
            <div class="ml-1 h4">
              {{ this.rowData.equipment_code }}
            </div>
            <div class="ml-1 h4 text-primary" text="primary">ปีงบประมาณ: :</div>
            <div class="ml-1 h4">{{ this.rowData.budget_year }}</div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary">กลุ่มงาน :</div>
            <div class="ml-1 h4">
              {{ this.rowData.work_gname }}
            </div>
            <!-- <div class="ml-1 h4 text-primary">ฝ่ายงาน:</div>
            <div class="ml-1 h4">ฝ่ายคลังข้อสอบ</div> -->
            <div class="ml-1 h4 text-primary">ผู้ยืม:</div>
            <div class="ml-1 h4">{{ this.rowData.req_name }}</div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">เหตุผล/ความจำเป็น:</div>
            <div class="ml-1 h4">{{ this.rowData.reason }}</div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">สถานะทรัพย์สิน :</div>
            <div class="ml-1 h4">{{ this.rowData.status_type }}</div>
            <div class="ml-1 h4 text-primary" text="primary">วันที่ยืม :</div>

            <div class="ml-1 h4">
              {{ this.rowData.takeback_date_th }}
            </div>
            <div class="ml-1 h4 text-primary" text="primary">วันที่คืน :</div>

            <div class="ml-1 h4">
              {{ this.rowData.takeback_date_happen_th }}
            </div>
          </div>

          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-end">
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none ml-1" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Excel
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
                  <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
                </span>

                <!-- Column: Action
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <feather-icon icon="GridIcon" class="mr-50" />
                      <span>QRCode</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span> -->

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3', '5', '10']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="handlePagechange($event)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import _ from "lodash";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);

export default {
  props: {
    rowData: Object,
  },
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  },

  data() {
    return {
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      ids: "",
      show: false,
      pageLength: 5,
      currentPage: 1,
      total: "",
      dir: false,
      selected: null,
      options: [
        { value: null, text: "เลือกประเภทหลักฐาน" },
        { value: "a", text: "ใบส่งของ/ใบกำกับาษี" },
        { value: "b", text: "ใบส่งของ/ใบกำกับาษี" },
        { value: "c", text: "ใบส่งของ/ใบกำกับาษี" },
        { value: "d", text: "ใบส่งของ/ใบกำกับาษี" },
        { value: "e", text: "ใบส่งของ/ใบกำกับาษี" },
        { value: "f", text: "ใบส่งของ/ใบกำกับาษี" },

        // { value: { C: '3PO' }, text: 'This is an option with object value' },
        // { value: 'd', text: 'This one is disabled', disabled: true },
      ],
      columns: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "SERIAL NUMBER",
          field: "serial_number",
        },
        {
          label: "รายการ",
          field: "e_name",
        },
        {
          label: "หน่วยนับ",
          field: "unittype",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ราคาต่อหน่วย + vat 7%",
          field: "unitprice_vat",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "หมายเหตุ",
          field: "remark",
        },
        // {
        //   field: "action",
        // },
      ],
      rows: [],
      searchTerm: "",
      status_user: "",
      username_user: "",
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    _.isEmpty(this.rowData) ? this.$router.push({ path: "/borrow-equipment" }) : true;

    this.get_table();
    const { usertype, username, fullname } = await JSON.parse(localStorage.getItem("username_type"));
    this.status_user = await usertype;
    this.username_user = await username;
    // console.log(fullname);
  },
  methods: {
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: `ทรัพย์สินคงคลัง ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`,
        columns: [
          {
            title: "รายการยืมทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: `เลขทะเบียน: ${this.rowData.equipment_code}`,
                headerCellOptions: { textAlign: "" },
                children: [
                  {
                    title: `กลุ่มงาน: ${this.rowData.work_gname}`,
                    headerCellOptions: { textAlign: "" },
                    children: [
                      {
                        title: `วันที่ยืม: ${this.rowData.takeback_date_th} วันที่คืน: ${this.rowData.takeback_date_happen_th}`,
                        headerCellOptions: { textAlign: "" },
                        children: [
                          {
                            title: "รายการยืมทรัพย์สิน",
                            field: "e_number",
                          },
                          {
                            title: "SERIAL NUMBER",
                            field: "serial_number",
                          },
                          {
                            title: "รายการ",
                            field: "e_name",
                          },
                          {
                            title: "หน่วยนับ",
                            field: "unittype",
                          },
                          {
                            title: "ราคาต่อหน่วย",
                            field: "unitprice_ex",
                            type: "number",
                          },
                          {
                            title: "ราคาต่อหน่วย + vat 7%",
                            field: "unitprice_vat_ex",
                            type: "number",
                          },
                          {
                            title: "หมายเหตุ",
                            field: "remark",
                          },
                        ],
                      },
                      {},
                    ],
                  },
                  {},
                ],
              },
              {},
            ],
          },
        ],
      });
    },
    async loan_approval(value) {
      console.log(value);

      this.ids = value;

      const { access_token } = await this.access_token();
      const url = `${API}borrowReturnsAssets/${this.rowData.equipment_code}`;
      const data = {
        status_type: this.ids,
        takeback_date_happen: this.rowData.takeback_date_happen,
        equipment_code: this.rowData.equipment_code,
      };
      console.log(data);
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res = await axios.put(url, data, header);
      console.log(res.data);
      if (res.data.status === 200) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "บันทึกสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async return_borrow() {
      const { access_token } = await this.access_token();
      const url = `${API}borrowReturnsAssets/${this.rowData.equipment_code}`;
      const data = {
        status_type: "ส่งคืน",
        takeback_date_happen: this.rowData.takeback_date_happen,
        equipment_code: this.rowData.equipment_code,
      };
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.put(url, data, header);
      // console.log(res.data.status);
      if (res.data.status === 200) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "ส่งคืน",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async get_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}borrowHistoryList?equipment_code=${this.rowData.equipment_code}&_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res.data.message);
      this.rows = res.data.message.data.map((el) => {
        return {
          ...el,
          unitprice_ex: Number(el.unitprice),
          unitprice_vat_ex: Number(el.unitprice_vat),
        };
      });
      this.total = res.data.message.total;
      this.show = false;
    },
    handlePagechange(event) {
      this.currentPage = event;
      this.get_table();
    },
    handleChangePage(event) {
      this.pageLength = event;
      this.get_table();
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    exportpdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [10, 170, 10, 180],
        pageSize: "A4",
        pageOrientation: "portrait",
        info: {
          title: "ใบยืมทรัพย์สิน",
        },
        header: [
          {
            text: `เลขที่ : ${this.rowData.equipment_code.split("-")[0]}-${this.rowData.equipment_code.split("-")[1]}/${
              this.rowData.equipment_code.split("-")[2]
            }
            วันที่ : ${this.rowData.borrow_date_th}`,
            style: "header",
            alignment: "right",
            margin: [0, 20, 20, 20],
          },
          {
            text: "ใบยืมทรัพย์สิน",
            style: "header",
            margin: [0, 15, 0, 0],
            alignment: "center",
          },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
            alignment: "center",
          },
          {
            text: `ชื่อกลุ่มงานที่ยืม: ${this.rowData.work_gname}`,
            margin: [10, 15, 0, 5],
            style: "header",
          },
          {
            text: `เหตุผล/ความจำเป็น: ${this.rowData.reason}`,
            margin: [10, 0, 0, 5],
            style: "header",
          },
          {
            text: `ระยะเวลาที่ยืม/วันที่คาดว่าเเล้วเสร็จ: ${this.rowData.takeback_date_happen_th}`,

            margin: [10, 0, 0, 5],
            style: "header",
          },
        ],
        footer: {
          columns: [
            {
              text: `(หัวหน้ากลุ่มงานรับรอง)....................................................................

                                    (....................................................................)







             วันที่คืน/ผู้คืน: ${this.rowData.takeback_date_happen_th}

             วันที่รับคืน/ผู้รับคืน: ${
               this.rowData.status_type === "ส่งคืน"
                 ? this.rowData.updatedAt_th
                 : "....................................................................."
             }
             

              `,
              alignment: "center",
              style: "text",
              margin: [20, 0, 0, 15],
            },

            {
              text: `(ลงชื่อ)....................................................................(ผู้ยืมทรัพย์สิน)
              ( ${
                this.rowData.req_name == null
                  ? "...................................................................."
                  : this.rowData.req_name
              } )
              วันที่ :  ${this.rowData.takeback_date_th}


               (ลงชื่อ)....................................................................(ผู้จ่าย)
              ( ${
                this.rowData.staff_name1 == null
                  ? "...................................................................."
                  : this.rowData.staff_name1
              } )
              วันที่ : .....................................................
              `,
              alignment: "center",
              style: "text",
              margin: [0, 0, 20, 15],
            },
          ],
        },
        content: [
          // {
          //   columns: [
          //     {
          //       text: `เลขทะเบียน : ${this.rowData.equipment_code}`,
          //       style: 'title',
          //     },
          //     {
          //       text: `วันที่ยืม ${this.rowData.borrow_date.split('-')[2]} ${this.month[parseInt(this.rowData.borrow_date.split('-')[1])]} ${
          //         Number(this.rowData.borrow_date.split('-')[0]) + 543
          //       }`,
          //       style: 'title',
          //     },
          //     {
          //       text: `วันที่คืน ${this.rowData.takeback_date.split('-')[2]} ${this.month[parseInt(this.rowData.takeback_date.split('-')[1])]} ${
          //         Number(this.rowData.takeback_date.split('-')[0]) + 543
          //       }`,
          //       style: 'title',
          //     },
          //     {
          //       text: `ปีงบประมาณ : ${this.rowData.budget_year}`,
          //       style: 'title',
          //     },
          //   ],
          // },
          // {
          //   columns: [
          //     {
          //       text: `กลุ่มงานผู้ขอยืม/ผู้ขอยืม : ${this.rowData.work_gname}`,
          //       style: 'title',
          //     },
          //     {
          //       text: `ฝ่ายงาน/ผู้ขอยืม : ${this.rowData.req_name}`,
          //       style: 'title',
          //     },
          //   ],
          // },

          {
            text: `รายละเอียดของวัสดุ ครุภัณฑ์ที่ยืม`,
            margin: [0, 0, 0, 0],
            style: "header",
          },
          {
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "*", "auto", "auto", "auto", "auto"],
              body: [
                [
                  { text: "ลำดับที่", style: "tableHeader" },
                  { text: "หมายเลขทรัพย์สิน", style: "tableHeader" },
                  { text: "SERIAL NUMBER", style: "tableHeader" },
                  { text: "รายการ", style: "tableHeader" },
                  { text: "หน่วยนับ", style: "tableHeader" },
                  { text: "ราคาต่อหน่วย", style: "tableHeader" },
                  { text: "ราคารวม VAT7%", style: "tableHeader" },
                  { text: "หมายเหตุ", style: "tableHeader" },
                ],
                ...this?.rows.map((res, index) => {
                  return [
                    { text: index + 1, style: "text_center" },
                    { text: res.e_number, style: "text_center" },
                    { text: res.serial_number, style: "text_center" },
                    { text: res.e_name, style: "text_left" },
                    { text: res.unittype, style: "text_center" },
                    {
                      text: res.unitprice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                      style: "text_right",
                    },
                    {
                      text: res.unitprice_vat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                      style: "text_right",
                    },
                    { text: "", style: "text_center" },
                  ];
                }),
              ],
            },
          },
        ],
        defaultStyle: {
          font: "Kanit",
        },
        text_header: {
          fontSize: 8,
        },
        styles: {
          tableExample: {
            margin: [0, 5, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 8,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 15, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            fontSize: 7,
          },
          text_right: {
            alignment: "right",
            fontSize: 7,
          },
          text_left: {
            alignment: "left",
            fontSize: 7,
          },
          text: {
            fontSize: 8,
          },
        },
      };
      pdfMake.createPdf(docDefinition).download(`ใบยืมทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
      // pdfMake.createPdf(docDefinition).open();
    },
    getfrom() {
      // console.log(id);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
